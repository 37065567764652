import React, { useState } from 'react';
import './Footer.css';

const Footer = () => {
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://formspree.io/f/mjkvyday', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, message })
      });
      
      if (response.ok) {
        setStatus('Thanks for your message!');
        setMessage('');
        setEmail('');
      } else {
        setStatus('Oops! There was a problem.');
      }
    } catch (error) {
      setStatus('Oops! There was a problem.');
    }
  };

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-left">
          <a href="/login" target="_blank" rel="noopener noreferrer">
            <img src="/logo192.png" alt="Logo" className="footer-logo" />
          </a>
        </div>
        <div className="footer-center">
          <form onSubmit={handleSubmit} className="message-box">
            <input 
              type="email" 
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Your email..." 
              className="message-input"
              required
            />
            <input 
              type="text" 
              name="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Leave us a message..." 
              className="message-input"
              required
            />
            <button type="submit" className="submit-button">Send</button>
          </form>
          {status && <p className="status-message">{status}</p>}
        </div>
        <div className="footer-right">
          <p className="copyright">© 2024 CW Stories. <br />All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
