import React, { useState } from 'react';
import './RegisterPage.css';

export default function RegisterPage() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');

    async function register(ev) {
        ev.preventDefault();
        try {
            const response = await fetch('http://localhost:4000/register', {
                method: 'POST',
                body: JSON.stringify({ username, password }),
                headers: { 'Content-Type': 'application/json' },
            });

            if (!response.ok) {
                setMessage('Registration failed :(');
                throw new Error('Registration failed');
            }

            const data = await response.json();
            setMessage('Registration successful!');
            console.log('Registration successful:', data);
        } catch (error) {
            console.error('Error during registration:', error);
        }
    }

    return (
        <div className="register">
            <form className="form" onSubmit={register}>
                <input type="text" className="form-input" 
                placeholder="Username" value={username} 
                onChange={ev => setUsername(ev.target.value)} 
                required />
                <input type="password" 
                className="form-input" 
                placeholder="Password" 
                value={password}
                onChange={ev => setPassword(ev.target.value)}
                required />
                <button type="submit" className="submit-button">Register</button>
            </form>
            {message && <p className="status-message">{message}</p>}
        </div>
    );
}