import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

const Header = () => {
  return (
    <nav className="header">
      <Link to="/" className="logo-container">
        <img src="/cwheaderlogo.png" alt="Logo" className="logo" />
        <div className="clickable-overlay" />
      </Link>
    </nav>
  );
};

export default Header;
