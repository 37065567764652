import React from 'react';
import './Story.css';

const Story3 = () => {
  return (
    <div className="story-page">
      <div className="story-content">
        <h1 className="title">LAB REPORT N°6</h1>
        <h2 className="date">
        Sartenejas, December 4th, 2012
        </h2>
        <p className="poem">
          “Kennari, kenndi mér ekki til einskis<br />
          óendanlega lygi í huga.<br />
          Miður bull vers<br />
          þýðandi.”<br />
        <strong>Davíð Guðmundsson</strong>
        </p>
        <p className="fullText">
        “I speak with the frankness of a motorcycle”. Such are the words of Carlos Anglada, one of the characters that appears in the book of short stories “6 Problemas para don Isidro Parodi” (6 Problems for Don Isidro Parodi). Anglada is not faithful to his words, his story is extended in details and metaphors, but he is not the only one, every time someone tells something to don Isidro he seems not to follow the words of Gervasio Montenegro: “Clarity is the privilege of the Latinos”. The author puts in the mouths of the characters a criticism that condemns them; like the poet with no capacity for synthesis, the actor who does not differentiate a play from reality and a swindled journalist.<br />< br/>
        Before continuing, it is necessary to clarify a few points about the author of the book, Honorio Bustos Domecq. If we turn to Wikipedia<sup><strong>1</strong></sup> we find that Domecq is a fabrication of Jorge Luis Borges and Adolfo Bioy Casares. The online encyclopedia has not obtained its bad prestige<sup><strong>2</strong></sup> in vain, here we are faced with one of the multiple errors (as infinite as the articles) of Wikipedia. Borges and Casares cannot be the creators of Domecq, the former was a writer of flat and gray prose whose only palpable style are his “less than” comparisons. We also cannot forget his reputation as a liar, creating authors<sup><strong>3</strong></sup> and works at will, it would not be unreasonable to think that he could also have appropriated the work of another writer. Nor could Casares have been part of such a project, his novels abound in absurd and unintelligible inventions for those who have no knowledge of physics. Not to mention the little care he takes in the preliminary research of his works, just read “La invencion del morel” (The Invention of Morel) where Venezuelans speak like Argentines and perform activities unthinkable for a citizen of Bolivar's great homeland. Both authors appropriated the work of an author who modestly did not complain. We must imagine Bustos enjoying such a spectacle, two porteño <i>hommes de lettres</i> stealing the <i>ouvre</i> of a poor provincial author. Domecq's prose is not pretentious and has the capacity to make the reader laugh, we could imagine him sitting at his desk writing in laughter, without the difficulties of one who writes with any kind of pretentiousness.<br /><br />
        The central figure in all the stories is on Isidro Parodi, owner of a barbershop, unjustly imprisoned for the murder of Agustín R. Bonorino. The real murderers, the Pata Santa gang, are not arrested because they were “a precious electoral element”. Social criticism makes its presence felt early on in the play. Populism, democracy and justice are questioned in what should be the harmless biography of a detective. Is the majority, due to being the majority, exempted from the law? Is the individual a vote or does the individual exercise their vote? These are questions that the author surely did not ask himself. Domecq, in an interview granted to La Nación<sup><strong>4</strong></sup> affirms that literature cannot serve as social criticism, its only function is that of entertainment. In the same interview he says that the authors who have most influenced his work are Poe and Sir Arthur Conan Doyle, exponents of enigma novels.<br /><br />
        It is no coincidence that the enigma novel, according to Todorov's definition<sup><strong>6</strong></sup>, is the genre in which “6 Problemas para don Isidro Parodi” can be framed. All the stories follow the same pattern; someone goes to cell 273 to tell Parodi about a crime, and after a week (when he receives a visit again) Parodi solves the case. All from the comfort of his cell, the detective is in no danger (S.S Van Dyne can rest easy).<i> “One can still characterize these two stories by saying that the first, that of the crime tells ‘what actually happened,’ while the second, that of the investigation, explains ‘how the reader (or the narrator) became aware of it.’</i><sup><strong>7</strong></sup> The first story is the one Parodi is told, the second is almost nonexistent. The resolution of the cases takes place in the detective's mind during the week between visits, this mental investigation is not revealed to the reader, who is only allowed to see the conclusion of the mental processes. The solutions are so simple that don Parodi can explain them in two sentences or even while playing cards (<i>"The 12 figures of the world"</i>).<br /><br />
        Is it possible to talk about an enigma novel that does not contain the story of the investigation? The answer seems to be that it cannot. “<i>An attempt was even made-which is very significant-to entirely suppress this second story: one publisher published real dossiers, composed of imaginary police reports, interrogations, photos, fingerprints, even locks of hair; these ‘authentic’ documents were to lead the reader to the discovery of the culprit (in case of defeat, a sealed envelope, pasted on the last page, gave the answer to the game: for example, the judge's verdict.</i>"<sup><strong>8</strong></sup> The procedure of the stories included in "6 Problems for Don Isidro Parodi” is similar, if not identical. The <i>last page</i> is the final chapter of the story, where the detective clears up the crime. The astute reader could do without the last chapter.<br /><br />
        We propose reading the final episode seven days [7, ∞) after finishing the penultimate one. This would imitate Parodi's investigation, turning the reader into another investigator. The reader cannot feel disadvantaged<sup><strong>9</strong></sup>, he has the same information as the detective.<br /><br />
        Don Isidro Parodi is one of the distinctive aspects of the story; in him we do not find the archetype of the detective in detective novels; he does not feel a special pleasure in solving puzzles, we could even say that he solves them with a hint of annoyance. The vocation for the profession found in Holmes or Dupin is imperceptible in Parodi, despite his apparent reluctance, he ends up being more rational and effective than any other detective.<sup><strong>10</strong></sup><br /><br />
        “6 Problems for Don Isidro Parodi” ends up becoming a sort of parody of the enigma novel, yet not for the fact of being a parody does it break with the schemes of the enigma novel; on the contrary, it fulfills almost all of them. We do not find in the works of Chesterton, Doyle, Christie or Poe, a story or novel as “enigma” as the work done by Honorio Bustos Domecq. He took all the rules to the extreme, to the point of showing how ridiculous the genre can be. We do not imagine that Domecq was thinking of making a mockery of the genre that he appreciated so much, rather it seems to have been the carelessness of a provincial writer who was unaware of literary theory.<br /><br />
        <i>Tumeremo, June 13th, 2009<br /> Francisco Díaz Sapero</i><br />
        </p>
        <h2 className="bibliography">
        Bibliography
        </h2>
        <p className="bibliography">
            <sup><strong>1</strong></sup>http://es.wikipedia.org/wiki/Honorio_Bustos_Domecq<br /><br />
            <sup><strong>2</strong></sup>http://www.newyorker.com/online/blogs/books/2012/09/an-open-letter-to-wikipedia.html<br /><br />
            <sup><strong>3</strong></sup>How many people were fooled by the infamous story-essay “Pierre Menard, author of Don Quixote”?<br /><br />
            <sup><strong>4</strong></sup>Impossible to locate the interview online, it was surely destroyed by impostors. In my hands I have what could be the last existing newspaper, dated June thirteenth (14), 1943<sup><strong>5</strong></sup> and printed in the city of Buenos Aires.<br /><br />
            <sup><strong>5</strong></sup> The newspaper came into my hands thanks to the combined efforts of a Spanish grandfather who lived in Argentina and a giant Real Madrid humiliating Barcelona. <i>Hala Madrid! Long live the King! To hell with the Catalans!</i><br /><br />
            <sup><strong>6 7 8</strong></sup><i>“Typology of the detective novel”, Tzvetan Todorov.</i><br /><br />
            <sup><strong>9</strong></sup>http://foroabiertodenovelanegra.wordpress.com/2008/08/29/reglas-de-la-novela-policiaca-s-s-van-dine/<br /><br />
            <sup><strong>10</strong></sup>He solves all the riddles from his cell, just by listening to a story.<br /><br />
        </p>
      </div>
    </div>
  );
};

// Add these exports to make the content available to other components
export const story3Content = {
  title: "LAB REPORT N°6",
  text: "“I speak with the frankness of a motorcycle”. Such are the words of Carlos Anglada, one of the characters that appears in the book of short stories “6 Problemas para don Isidro Parodi” (6 Problems for Don Isidro Parodi).",
};

export default Story3;
