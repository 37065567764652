import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './HomePage.css';
import { story1Content } from './Stories/Story1';
import { story2Content } from './Stories/Story2';
import { story3Content } from './Stories/Story3';
import { story4Content } from './Stories/Story4';
import { story5Content } from './Stories/Story5';
import { story6Content } from './Stories/Story6';

const HomePage = () => {
  const [hoveredSection, setHoveredSection] = useState(null);
  const navigate = useNavigate();

  const handleClick = (index) => {
    navigate(`/story${index + 1}`);
  };

  const stories = [
    { title: story1Content.title, text: story1Content.text },
    { title: story2Content.title, text: story2Content.text },
    { title: story3Content.title, text: story3Content.text },
    { title: story4Content.title, text: story4Content.text },
    { title: story5Content.title, text: story5Content.text },
    { title: story6Content.title, text: story6Content.text },
  ];

  return (
    <div className="homepage">
      <div className="stories-container">
        {stories.map((story, index) => (
          <div 
            key={index} 
            className={`section ${hoveredSection === index ? 'expanded' : ''}`}
            onClick={() => handleClick(index)}
            onMouseEnter={() => setHoveredSection(index)}
            onMouseLeave={() => setHoveredSection(null)}
          >
            <div className="horizontal-divider">
              <span className="divider-text">STORY {index + 1}</span>
            </div>
            <h2>{story.title}</h2>
            <div className={`story-content ${hoveredSection === index ? 'show' : ''}`}>
              <p>{story.text}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomePage;
