import React from 'react';
import './Story.css';

const Story4 = () => {
  return (
    <div className="story-page">
      <div className="story-content">
        <h2>The Last Train Home</h2>
        <p>
          The midnight train whistled through the empty station, its echo bouncing off the worn tiles like a ghost's whisper. The rest of that cold November night would change everything I thought I knew about reality.
        </p>
      </div>
    </div>
  );
};

// Add these exports to make the content available to other components
export const story4Content = {
  title: "The Last Train Home",
  text: "The midnight train whistled through the empty station, its echo bouncing off the worn tiles like a ghost's whisper.",
  fullText: "The midnight train whistled through the empty station, its echo bouncing off the worn tiles like a ghost's whisper. The rest of that cold November night would change everything I thought I knew about reality."
};

export default Story4;
