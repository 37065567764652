import React, { useState } from 'react';
import './LoginPage.css';

export default function LoginPage() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState(''); // Add message state for feedback

    async function login(ev) {
        ev.preventDefault();
        try {
            const response = await fetch('http://localhost:4000/login', { // Use /login endpoint
                method: 'POST',
                body: JSON.stringify({ username, password }),
                headers: { 'Content-Type': 'application/json' },
            });

            if (!response.ok) {
                setMessage('Login failed :('); // Update message for login
                throw new Error('Login failed');
            }

            const data = await response.json();
            setMessage('Login successful!');
            console.log('Login successful:', data);
        } catch (error) {
            console.error('Error during login:', error);
        }
    }

    return (
        <div className="login">
            <form className="form" onSubmit={login}> {/* Add onSubmit to form */}
                <input 
                    type="text" 
                    className="form-input" 
                    placeholder="Username" 
                    value={username} 
                    onChange={ev => setUsername(ev.target.value)} 
                    required 
                />
                <input 
                    type="password" 
                    className="form-input" 
                    placeholder="Password" 
                    value={password} 
                    onChange={ev => setPassword(ev.target.value)} 
                    required 
                />
                <button type="submit" className="submit-button">Login</button>
            </form>
            {message && <p className="status-message">{message}</p>} {/* Display login message */}
        </div>
    );
}
