// src/App.js
import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Header from './Views/Header.js';
import HomePage from './Views/HomePage.js';
import Footer from './Views/Footer.js';
import LoginPage from './Views/LoginPage.js';
import RegisterPage from './Views/RegisterPage.js';
import Story1 from './Views/Stories/Story1';
import Story2 from './Views/Stories/Story2';
import Story3 from './Views/Stories/Story3';
import Story4 from './Views/Stories/Story4';
import Story5 from './Views/Stories/Story5';
import Story6 from './Views/Stories/Story6';


const App = () => {
  const location = useLocation();

  // Define paths where the footer should not be displayed
  const noFooterPaths = ['/story1', '/story2', '/story3', '/story4', '/story5', '/story6']; // Add all story paths here

  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/story1" element={<Story1 />} />
        <Route path="/story2" element={<Story2 />} />
        <Route path="/story3" element={<Story3 />} />
        <Route path="/story4" element={<Story4 />} />
        <Route path="/story5" element={<Story5 />} />
        <Route path="/story6" element={<Story6 />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
      </Routes>
      {/* Conditionally render the Footer based on the current path */}
      {!noFooterPaths.includes(location.pathname) && <Footer />}
    </div>
  );
};

const MainApp = () => (
  <Router>
    <App />
  </Router>
);

export default MainApp;
